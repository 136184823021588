const BASE_URL = process.env.REACT_APP_BASE_URL;
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

async function postApi(path, body, bearer) {
  const strBody = typeof body === "string" ? body : JSON.stringify(body);
  const useBearer = bearer === false ? false : true;
  const params = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
    mode: "cors",
    body: strBody,
  };
  if (useBearer)
    params.headers["Authorization"] = `BEARER ${localStorage.getItem(
      "access_token"
    )}`;
  console.log("using params", params);
  const data = await fetch(`${BASE_URL}${path}`, params);
  const response = await data.json();
  if (data.status === 200) {
    return response.result;
  } else {
    console.log("ERROR:", data);
    const error = new Error(data.statusText);
    error.code = data.status;
    throw error;
  }
}

async function patchApi(path, body) {
  const strBody = typeof body === "string" ? body : JSON.stringify(body);
  console.log("patching body " + strBody.length + " bytes long", strBody);
  const data = await fetch(`${BASE_URL}${path}`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `BEARER ${localStorage.getItem("access_token")}`,
    },
    method: "PATCH",
    mode: "cors",
    body: strBody,
  });

  const response = await data.json();
  if (data.status === 200) {
    return response.result;
  } else {
    const error = new Error(data.statusText);
    error.code = data.status;
    throw error;
  }
}

async function getApi(path, query) {
  const queryPart = query !== undefined ? `?${query}`: '';
  const data = await fetch(`${BASE_URL}${path}${queryPart}`, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `BEARER ${localStorage.getItem('access_token')}`
    },
    credentials: 'include',
//        mode: 'cors',
    method: 'GET'
  });
  const response = await data.json();
  if (data.status === 200) {
    return response.result;
  } else {
    const error = new Error(response.message);
    error.code = data.status;
    throw error;
  }
}

async function deleteApi(path, query) {
  const queryPart = query !== undefined ? `?${query}` : "";
  const data = await fetch(`${BASE_URL}${path}${queryPart}`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      access_token: localStorage.getItem("access_token"),
    },
    mode: "cors",
    method: "DELETE",
  });
  const response = await data.json();
  console.log("got after get:", response);
  if (data.status === 200) {
    return response.result;
  } else {
    const error = new Error(data.statusText);
    error.code = data.status;
    throw error;
  }
}
export class Users {
  async authenticate(email, password) {
    const body = { email, password };
    const user = await postApi("/users/authenticate", body);
    if (user.role !== "super_admin") {
      throw new Error("user is not super admin");
    }
    localStorage.setItem("current_user", JSON.stringify(user));
    localStorage.setItem("access_token", user.access_token);
    return user;
  }
  async create(email, password) {
    const body = { email, password, username: email };
    const createdUser = await postApi("/users", body);
    return createdUser;
  }
  async update(user) {
    return await patchApi(`/users`, user);
  }

  async getAll() {
    const params = {
      page_index: 0,
      page_size: 1000, // TODO add pagination and search possibility
      sort: "-updated_at",
    };
    return await postApi("/users/search", params);
  }
  async getOrganizations() {
    const params = {
      page_index: 0,
      page_size: 1000,
      sort: "-updated_at",
    };
    return await postApi("/organizations/search", params);
  }

  async createOrganization(doc) {
    return await postApi("/organizations", { name: doc.name });
  }

  async updateOrganization(updateDoc) {
    return await patchApi(`/organizations/${updateDoc.id}/`, {
      name: updateDoc.name,
      two_step_authentication: updateDoc.two_step_authentication || '',
    });
  }
  async deleteOrganization(id) {
    // return await deleteApi(`/organizations/${id}`);
    console.log("not implemented");
  }
}

export class Documents {
  async getDocumentsFromSearch(text) {
    return await getApi("/documents/search", `page_index=0&page_size=40&text=${text}`);
  }

  async updateDocument(id, body) {
    const document = await patchApi(`/documents/${id}/`, body);
    return document;
  }

  async getDocumentsTemplates() {
    return await getApi("/documents/templates", `page_index=0&page_size=40`);
  }

  async copyDocument(docId) {
    return getApi(`/documents/${docId}/clone`);
  }

  async updateDocumentOrganization(docId, organizationId) {
    return await postApi(`/documents/${docId}/assignOrganization/${organizationId}`);
  }

}

const def = {
  users: new Users(),
  documents: new Documents(),
};
export { def as default, SERVER_URL, BASE_URL };
